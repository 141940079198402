import React from "react";
import styles from './EntriesList.module.sass';
import down from "./assets/downImg.svg";
import { useRecoilValue } from "recoil";
import entriesAtom from "../../recoil/entries";
import { TrafficStatsFooter } from "../TrafficViewer/TrafficStatsFooter/TrafficStatsFooter";
import { TrafficEntry } from "../TrafficEntry/TrafficEntry";
import { Entry } from '../EntryListItem/Entry'
import ScrollableFeedVirtualized from '../UI/ScrollableFeedVirtualized/ScrollableFeedVirtualized'

interface EntriesListProps {
  listEntryREF: React.LegacyRef<HTMLDivElement>;
  onSnapBrokenEvent: () => void;
  isSnappedToBottom: boolean;
  setIsSnappedToBottom: (state: boolean) => void;
  scrollableRef: React.MutableRefObject<ScrollableFeedVirtualized<Entry>>;
}

export const EntriesList: React.FC<EntriesListProps> = ({
  listEntryREF,
  onSnapBrokenEvent,
  isSnappedToBottom,
  setIsSnappedToBottom,
  scrollableRef,
}) => {
  const entries = useRecoilValue(entriesAtom);

  const renderItem = (item: Entry, index: number) => {
    return (
      <TrafficEntry
        key={index}
        entry={item}
        duplicateOrigin={
          item.duplicate !== '' ? entries.get(item.duplicate) : null
        }
        style={{}}
      />
    );
  };

  return <React.Fragment>
    <div className={styles.list}>
      <div id="list" ref={listEntryREF} className={styles.list}>
        <ScrollableFeedVirtualized
          className={styles.scrollableFeed}
          ref={scrollableRef}
          itemHeight={52}
          itemCount={entries.size}
          renderItem={renderItem}
          items={entries}
          marginTop={10}
          onSnapBroken={onSnapBrokenEvent}
        />
        <button type="button"
          title="Snap to bottom"
          className={`${styles.btnLive} ${isSnappedToBottom ? styles.hideButton : styles.showButton}`}
          onClick={() => {
            scrollableRef.current.jumpToBottom();
            setIsSnappedToBottom(true);
          }}>
          <img alt="down" src={down} />
        </button>
      </div>
      <TrafficStatsFooter />
    </div>
  </React.Fragment>;
};
