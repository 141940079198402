import React from 'react'
import { Box } from '@mui/material'
import Queryable from '../../../UI/Queryable/Queryable'

import { EntryPoint } from '../../../../types/trafficEntry'
import { EntrySource } from '../ResolutionData'

import classNames from 'classnames'
import styles from './IpWithPort.module.sass'
import '../../TrafficEntry.module.sass'


interface IpWithPortProps {
  ip: string
  port: string
  point: EntryPoint
}

export const IpWithPort: React.FC<IpWithPortProps> = ({ ip, port, point }) => {
  const justifyContent = point === EntrySource ? styles.IpWithPortEnd : styles.IpWithPortStart

  return (
    <Box className={classNames(styles.IpWithPort, justifyContent)}>
      <Queryable
        query={`${point}.ip == "${ip}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={point === EntrySource ? {
          position: 'absolute',
          top: '12px'
        } : {
          position: 'absolute',
          top: '12px',
          right: '50px'
        }}
        tooltipStyle={point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: '25px',
          top: '-5px'
        }}
      >
        <span className='DataText'>{ip}:</span>
      </Queryable>
      <Queryable
        query={`${point}.port == "${port}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={point === EntrySource ? {
          position: 'absolute',
          top: '12px'
        } : {
          position: 'absolute',
          top: '12px',
          right: '30px'
        }}
        tooltipStyle={ point === EntrySource ? {
          position: 'absolute',
          top: '-5px'
        } : {
          position: 'absolute',
          right: 0,
          top: '-5px'
        }}
      >
        <span className='DataText'>{port}</span>
      </Queryable>
    </Box>
  )
}
