import React, { useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './Queryable.module.sass';
import { useRecoilState } from "recoil";
import queryBuildAtom from "../../../recoil/queryBuild";
import classNames from 'classnames'
import { heapCacheString } from '../../../helpers/cacher/cacher'

interface Props {
  query: string,
  queryNote?: string,
  joinCondition?: "or" | "and"
  style?: unknown,
  iconStyle?: unknown,
  className?: string,
  useTooltip?: boolean,
  tooltipStyle?: unknown,
  displayIconOnMouseOver?: boolean,
  displayImmediately?: boolean,
  flipped?: boolean,
  onAdded?: () => void
}

const splitByConditionsPattern = /\s+(or|and)\s+/
const identifyByConditionsPattern = /or|and/

const Queryable: React.FC<Props> = ({ query: rawQuery, queryNote, joinCondition = "and", style, iconStyle, className, useTooltip = true, tooltipStyle = null, displayIconOnMouseOver = false, displayImmediately = false, flipped = false, onAdded, children }) => {
  const [showAddedNotification, setAdded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [queryBuild, setQuerytemp] = useRecoilState(queryBuildAtom);
  const [queryChunks, setQueryChunks] = useState([])

  const query = heapCacheString(rawQuery)

  const onCopy = () => {
    setAdded(true)
  };

  const formatQuery = (query) => {
    if (query.match(splitByConditionsPattern)) {
      query = `(${query})`
    }

    return query
  }

  useEffect(() => {
    const queryChunks = query.split(splitByConditionsPattern)
    setQueryChunks(queryChunks)

    let timer;
    if (showAddedNotification) {

      const formattedQuery = formatQuery(query)
      setQuerytemp(queryBuild ? `${queryBuild} ${joinCondition} ${formattedQuery}` : formattedQuery);

      timer = setTimeout(() => {
        if (onAdded !== undefined) {
          onAdded()
        }
        setAdded(false);
      }, 1000);
    }
    return () => clearTimeout(timer);

    // eslint-disable-next-line
  }, [showAddedNotification, query, setQuerytemp]);

  useEffect(() => setShowTooltip(displayImmediately), [displayImmediately])

  const addButton = query ? <CopyToClipboard text={query} onCopy={onCopy}>
    <span
      onClick={event => event.stopPropagation()}
      className='QueryableIcon'
      title={`Add "${query}" to the filter ${queryNote !== undefined ? `(${queryNote})` : ''}`}
      style={iconStyle}>
      <AddCircleIcon fontSize="small" color="inherit" />
      {showAddedNotification && <span className='QueryableAddNotifier'>Added</span>}
    </span>
  </CopyToClipboard> : null;

  const displayIcon = displayImmediately ? 'displayIconImmediately' : 'displayIconOnMouseOver'

  const getFormattedQuery = () => (
    queryChunks.map((chunk, index) => (
      <React.Fragment key={index}>
        {chunk} {chunk.match(identifyByConditionsPattern) && <br/>}
      </React.Fragment>
    ))
  );

  const queryableClasses = classNames(
    'QueryableContainer',
    displayIcon,
    className,
    { ['displayIconOnMouseOver']: displayIconOnMouseOver }
  );

  const handleMouseOver = useCallback(() => {
    if (!displayImmediately) {
      setShowTooltip(true);
    }
  }, [displayImmediately]);

  const handleMouseLeave = useCallback(() => {
    if (!displayImmediately) {
      setShowTooltip(false);
    }
  }, [displayImmediately]);

  return (
    <div className={queryableClasses}
      style={style} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {flipped && showTooltip && addButton}
      {children}
      {!flipped && showTooltip && addButton}
      {useTooltip && showTooltip && (query !== "") && <span data-cy={"QueryableTooltip"} className='QueryableTooltip' style={tooltipStyle}>
        {getFormattedQuery()}
        {queryNote !== undefined && <>
          <hr />
          <span><strong>Note:</strong> {queryNote}</span>
        </>}
      </span>}
    </div>
  );
};

export default Queryable;
