import * as React from 'react'
import styles from './ScrollableFeedVirtualized.module.sass'

const buffer = 3;

export type ScrollableFeedVirtualizedProps<T> = {
  itemHeight: number;
  marginTop: number;
  animateScroll?: (element: HTMLElement, offset: number) => void;
  onScrollComplete?: () => void;
  viewableDetectionEpsilon?: number;
  className?: string;
  onSnapBroken?: () => void;
  scrollBarHorizontalGap?: number;
  itemCount: number;
  renderItem: (item: T, index: number) => React.ReactNode; // Adjusted to receive Item
  items: Map<string, T>; // Map of items
};

// TODO: Transform into React Functional Component (efficient state management)
// See: https://react.dev/reference/react/Component

class ScrollableFeedVirtualized<T> extends React.Component<ScrollableFeedVirtualizedProps<T>> {
  private readonly wrapperRef: React.RefObject<HTMLDivElement>;
  private readonly childWrapperRef: React.RefObject<HTMLDivElement>;
  private readonly topRef: React.RefObject<HTMLDivElement>;
  private readonly bottomRef: React.RefObject<HTMLDivElement>;
  private forceScroll: boolean;
  private startIndexOverride: number;
  private endIndexOverride: number;

  constructor(props: ScrollableFeedVirtualizedProps<T>) {
    super(props);
    this.wrapperRef = React.createRef();
    this.childWrapperRef = React.createRef();
    this.topRef = React.createRef();
    this.bottomRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.forceScroll = true;
    this.startIndexOverride = 0;
    this.endIndexOverride = 0;
  }

  static defaultProps = {
    itemHeight: 0,
    marginTop: 0,
    animateScroll: (element: HTMLElement, offset: number): void => {
      if (element.scrollBy) {
        element.scrollBy({ top: offset });
      } else {
        element.scrollTop = offset;
      }
    },
    onScrollComplete: () => {
      return
    },
    viewableDetectionEpsilon: 2,
    onSnapBroken: () => {
      return
    },
  };

  getSnapshotBeforeUpdate(): boolean {
    if (this.wrapperRef.current && this.bottomRef.current) {
      const { viewableDetectionEpsilon } = this.props;
      return ScrollableFeedVirtualized.isViewable(this.wrapperRef.current, this.bottomRef.current, viewableDetectionEpsilon);
    }
    return false;
  }

  componentDidUpdate(): void {
    if (this.forceScroll) {
      this.scrollToBottom();
    }
  }

  componentDidMount(): void {
    this.scrollToBottom();
  }

  protected scrollParentToChild(parent: HTMLElement, child: HTMLElement): void {
    const { viewableDetectionEpsilon } = this.props;
    if (!ScrollableFeedVirtualized.isViewable(parent, child, viewableDetectionEpsilon)) {
      const parentRect = parent.getBoundingClientRect();
      const childRect = child.getBoundingClientRect();

      const scrollOffset = (childRect.top + parent.scrollTop) - parentRect.top;
      const { animateScroll, onScrollComplete } = this.props;
      if (animateScroll) {
        animateScroll(parent, scrollOffset);
        if (onScrollComplete) {
          onScrollComplete();
        }
      }
    }
  }

  private static isViewable(parent: HTMLElement, child: HTMLElement, epsilon: number): boolean {
    epsilon = epsilon || 0;

    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const childTopIsViewable = (childRect.top >= parentRect.top);

    const childOffsetToParentBottom = parentRect.top + parent.clientHeight - childRect.top;
    const childBottomIsViewable = childOffsetToParentBottom + epsilon >= 0;

    return childTopIsViewable && childBottomIsViewable;
  }

  protected handleKeyDown(e): void {
    const { onSnapBroken } = this.props;
    switch (e.keyCode) {
    case 33: // PageUp
    case 38: // ArrowUp
      this.forceScroll = false;
      if (onSnapBroken) {
        onSnapBroken();
      }
      break;
    case 145: // ScrollLock
      this.forceScroll = !this.forceScroll;
      if (onSnapBroken) {
        onSnapBroken();
      }
      break;
    default:
      break;
    }
  }

  protected handleMouseWheel(): void {
    const { onSnapBroken } = this.props;
    this.forceScroll = false;
    if (onSnapBroken) {
      onSnapBroken();
    }
  }

  protected handleMouseDown(e): void {
    const { scrollBarHorizontalGap, onSnapBroken } = this.props;
    const gap = scrollBarHorizontalGap ? scrollBarHorizontalGap : 16;
    if (this.wrapperRef.current === e.target && (e.clientX - gap) >= e.target.clientWidth) {
      this.forceScroll = false;
      if (onSnapBroken) {
        onSnapBroken();
      }
    }
  }

  protected handleScroll(): void {
    this.forceUpdate();
  }

  public scrollToBottom(): void {
    if (this.wrapperRef.current) {
      const parent = this.wrapperRef.current;
      const { animateScroll, onScrollComplete, items, itemHeight } = this.props;
      const numItems = items.size;
      if (animateScroll && parent) {
        animateScroll(parent, (numItems + buffer) * itemHeight);
        if (onScrollComplete) {
          onScrollComplete();
        }
      }
    }
  }

  public scrollToIndex(startIndex: number): void {
    const { itemHeight, marginTop, animateScroll, onScrollComplete } = this.props;
    if (this.wrapperRef.current) {
      const parent = this.wrapperRef.current;
      const upperParent = this.wrapperRef.current.parentElement;
      if (upperParent) {
        const upperParentRect = upperParent.getBoundingClientRect();
        const windowHeight = upperParentRect.height;
        const actualHeight = itemHeight + marginTop;

        if (animateScroll && parent) {
          animateScroll(parent, (startIndex + Math.floor(windowHeight / actualHeight) + 2 * buffer) * itemHeight);
          if (onScrollComplete) {
            onScrollComplete();
          }
        }
      }
    }
  }

  public jumpToBottom(): void {
    const { items, itemHeight, marginTop } = this.props;
    const numItems = items.size;
    if (this.wrapperRef.current) {
      const upperParent = this.wrapperRef.current.parentElement;
      if (upperParent) {
        const upperParentRect = upperParent.getBoundingClientRect();
        const windowHeight = upperParentRect.height;
        const actualHeight = itemHeight + marginTop;

        this.startIndexOverride = numItems - Math.floor(windowHeight / actualHeight) - buffer;
        this.endIndexOverride = numItems - 1;
        this.forceUpdate();
        this.forceScroll = true;
        this.scrollToBottom();
      }
    }
  }

  render(): React.ReactNode {
    const { className, itemHeight, marginTop, items } = this.props;
    const numItems = items.size;
    // Convert Map to Array
    const childrenArr = Array.from(items.values());

    if (childrenArr.length === 0) {
      return <></>;
    }

    let windowHeight = 0;
    let windowTop = 0;

    if (this.wrapperRef.current) {
      const upperParent = this.wrapperRef.current.parentElement;
      if (upperParent) {
        const upperParentRect = upperParent.getBoundingClientRect();
        windowHeight = upperParentRect.height;
        windowTop = upperParentRect.top;

        const { animateScroll, onScrollComplete } = this.props;
        if (animateScroll && upperParent) {
          animateScroll(upperParent, (numItems + buffer) * itemHeight);
          if (onScrollComplete) {
            onScrollComplete();
          }
        }
      }
    }

    let top = 0;

    if (this.topRef.current) {
      const topRect = this.topRef.current.getBoundingClientRect();
      if (topRect.top < windowTop) {
        top = windowTop - topRect.top;
      }
    }

    const actualHeight = itemHeight + marginTop;
    let startIndex = Math.floor(top / actualHeight);
    let endIndex = Math.min(
      numItems - 1,
      Math.floor((top + windowHeight) / actualHeight)
    );

    startIndex -= buffer;
    endIndex += buffer;

    if (startIndex < 0) {
      startIndex = 0;
    }

    if (this.startIndexOverride > 0) {
      startIndex = this.startIndexOverride;
      this.startIndexOverride = 0;
    }

    if (this.endIndexOverride > 0) {
      endIndex = this.endIndexOverride;
      this.endIndexOverride = 0;
    }

    if (endIndex > (numItems - 1)) {
      endIndex = (numItems - 1);
    }

    const itemsToRender = childrenArr.slice(startIndex, endIndex + 1).map((item, index) => {
      const itemIndex = startIndex + index;
      return (
        <div
          key={itemIndex}
          style={{
            position: 'absolute',
            top: `${itemIndex * actualHeight}px`,
            marginTop: `${marginTop}px`,
            width: '100%',
          }}
        >
          {this.props.renderItem(item, itemIndex)}
        </div>
      );
    });

    const childWrapperHeight = actualHeight * numItems;

    const joinedClassName = styles.scrollableDiv + (className ? " " + className : "");
    return (
      <div
        className={joinedClassName}
        ref={this.wrapperRef}
        onScroll={this.handleScroll}
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleMouseWheel}
        onMouseDown={this.handleMouseDown}
        tabIndex={0}
      >
        <div ref={this.childWrapperRef} style={{ height: `${childWrapperHeight}px` }}>
          <div ref={this.topRef}></div>
          {itemsToRender}
          <div ref={this.bottomRef}></div>
        </div>
      </div>
    );
  }
}

export default ScrollableFeedVirtualized;
